import { initializeApp } from 'firebase/app';
import { getAuth, inMemoryPersistence, setPersistence } from 'firebase/auth';
import { getStorage } from '@firebase/storage';

const config = JSON.parse(window.ENV.FIREBASE_CLIENT_JSON);

const app = initializeApp(config);

const auth = getAuth(app);
const storage = getStorage(app);
setPersistence(auth, inMemoryPersistence);

export { auth, storage };
