import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import * as React from 'react';
import { BiLogoApple, BiLogoFacebook, BiLogoTwitter } from 'react-icons/bi';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineError } from 'react-icons/md';
import { useTypedFetcher } from 'remix-typedjson';
import { Button } from '~/cds/Button';
import { Typography } from '~/common/components/cds/Typography';
import { ERROR_NO_PROVIDER } from '~/common/constants/auth';
import { AuthProviderIdentity } from '~/common/types';
import { auth } from '~/firebase.client';

interface AuthProviderProps {
    socialLogins?: boolean;
}

export const AuthProvider = ({ socialLogins }: AuthProviderProps) => {
    const fetcher = useTypedFetcher();
    const [socialAuthError, setSocialAuthError] = React.useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionSocialAuthError = (fetcher.data as any)?.socialAuthError;

    const handleSocialProvider = async (
        selectedProvider: AuthProviderIdentity
    ) => {
        let provider;
        switch (selectedProvider) {
            case AuthProviderIdentity.GOOGLE: {
                provider = new GoogleAuthProvider();
                break;
            }
        }

        if (!provider) {
            setSocialAuthError(ERROR_NO_PROVIDER);
        }

        try {
            const firebaseUser = await signInWithPopup(auth, provider);
            console.log('firebaseUser', firebaseUser);
            if (firebaseUser && firebaseUser.user.uid) {
                fetcher.submit(
                    {
                        socialAuthUserId: firebaseUser.user.uid,
                        email: firebaseUser.user.email,
                        display_name: firebaseUser.user.displayName,
                        photo_url: firebaseUser.user.photoURL,
                    },
                    { method: 'POST' }
                );
            }
        } catch (e) {
            if (e instanceof Error) setSocialAuthError(e.message);
        }
    };

    if (!socialLogins) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="relative">
                <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                >
                    <div className="w-full border-t border-gray-100" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-6 text-[14px] leading-[20px] tracking-[-0.28px] text-gray-500">
                        Or continue with
                    </span>
                </div>
            </div>
            <div className="flex gap-x-5 xs:justify-center md:justify-start">
                <Button
                    className="border border-gray-100 bg-white hover:bg-white"
                    iconOnly={<FcGoogle className="h-6 w-6 flex-shrink-0" />}
                    size="lg"
                    onClick={() =>
                        handleSocialProvider(AuthProviderIdentity.GOOGLE)
                    }
                />
                <Button
                    className="bg-[#1877F2] hover:bg-[#1877F2]"
                    iconOnly={
                        <BiLogoFacebook className="h-6 w-6 flex-shrink-0 text-white" />
                    }
                    size="lg"
                    disabled
                />
                <Button
                    className="bg-[#1D9BF0] hover:bg-[#1D9BF0]"
                    iconOnly={
                        <BiLogoTwitter className="h-6 w-6 flex-shrink-0 text-white" />
                    }
                    size="lg"
                    disabled
                />
                <Button
                    className="bg-[#000000] hover:bg-[#000000]"
                    iconOnly={
                        <BiLogoApple className="h-6 w-6 flex-shrink-0 text-white" />
                    }
                    size="lg"
                    disabled
                />
            </div>
            {(socialAuthError || actionSocialAuthError) && (
                <div className="flex items-center gap-3 rounded-md bg-red-25 p-4">
                    <MdOutlineError className="h-6 w-6 text-red-500" />
                    <Typography intent="body" size="sm">
                        {socialAuthError || actionSocialAuthError}
                    </Typography>
                </div>
            )}
        </React.Fragment>
    );
};
